import Image from "next/image";
import React, {useState} from "react";

import {POSITIONS} from '@/configuration/static-data-provider';
import {GlobalSettings} from "@/configuration/global-settings";
import SharedServiceInstance from "@/services/shared-service";
import {
    Select, SelectItem, Input, Button, Spinner, useDisclosure, Link,
    Modal, ModalContent, ModalHeader, ModalBody, ModalFooter
} from "@nextui-org/react";

var _ = require('lodash');

import ClergiesBlock from "@/components/shared/clergies-block";

export default function SearchClergiesAppointment(deaneries) {
    const deaneriesData = deaneries.data;
    const sharedService = SharedServiceInstance;
    const workspaceSettings = SharedServiceInstance.workingSpaceSettings;

    const [clergies, setClergies] = useState([]);
    const [clergyFirst, setClergyFirst] = useState();
    const [deaneryValue, setDeaneryValue] = useState("");
    const [positionValue, setPositionValue] = useState("");
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [priestNameValue, setPriestNameValue] = useState("");
    const {isOpen, onOpen, onClose} = useDisclosure();

    const handlePriestNameChange = (e) => {
        setTriggerSearch(false);
        setPriestNameValue(e.target.value);
    };

    const fetchData = async () => {
        let payload = {
            pageSize: 300,
            groupID: deaneryValue,
            position: positionValue,
            clergyName: priestNameValue
        }
        const response = await fetch('api/clergies', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const responseClergies = await response.json();
        setClergies(responseClergies);
        setTriggerSearch(true);
        if (responseClergies.total > 0) {
            setClergyFirst(_.head(responseClergies.value));
        }
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData();
    };
    const openDetail = (event) => {
        onOpen();
    }

    const clergiesModal = <>
        <Modal backdrop="blur" isOpen={isOpen} onClose={onClose} size="full" scrollBehavior="inside">
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1 text-3xl font-bold text-center">Linh Mục Giáo Phận</ModalHeader>
                        <ModalBody>
                            <div className="search-modal">
                                <div className={`search-modal--inputs lg:grid grid-cols-7 bg-primary py-3`}>
                                    <div className="col-span-2 my-5 mx-5 mac:my-5">
                                        <Select
                                            items={POSITIONS}
                                            size="sm"
                                            placeholder="Chọn Chức Vụ"
                                            className=""
                                            defaultSelectedKeys={positionValue ? [positionValue] : []}
                                            onChange={(e) => setPositionValue(e.target.value)}
                                        >
                                            {(position) => <SelectItem
                                                key={position.code}>{position.name}</SelectItem>}
                                        </Select>
                                    </div>
                                    <div className="col-span-2 my-5 mx-5">
                                        <Select
                                            items={deaneriesData}
                                            size="sm"
                                            placeholder="Chọn Giáo Hạt"
                                            defaultSelectedKeys={deaneryValue ? [deaneryValue] : []}
                                            onChange={(e) => setDeaneryValue(e.target.value)}
                                            className=""
                                        >
                                            {deaneriesData.value.map((appointment) => {
                                                return (
                                                    <SelectItem key={appointment.id}>
                                                        {appointment.name}
                                                    </SelectItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                    <div className="col-span-2 my-5 mx-5 mac:my-5">
                                        <Input
                                            size="lg"
                                            key="none"
                                            radius="sm"
                                            type="text"
                                            placeholder="Nhập Tên"
                                            className="font-semibold"
                                            defaultValue={priestNameValue}
                                            onChange={handlePriestNameChange}
                                        />
                                    </div>
                                    <div className="pb-5 lg:pb-0 lg:my-5 col-span-1 mx-auto text-center">
                                        <Button size="lg" onClick={handleSearch} className="rounded bg-white">
                                            TÌM KIẾM
                                        </Button>
                                    </div>
                                </div>
                                <div className="search-modal-contain bg-secondary">
                                    {triggerSearch && clergies.total === 0 && <>
                                        <div class="h-56 grid content-center mx-auto">{}
                                            <Spinner label={`Không tim thấy ` + priestNameValue} color="primary" labelColor="primary" className="italic" size="lg" />
                                        </div>
                                    </>}

                                    <ClergiesBlock clergies={clergies} isPagination={false} />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    </>;
    const titleClergiesAppointment = <>
        <Link href={`/linh-muc-doan`} className="font-bold text-center text-white text-2xl">
            Linh Mục Giáo Phận
        </Link>
    </>
    const titleClergiesAppointmentImg = (
        <>
            <Image
                className="flex justify-center"
                src={
                    workspaceSettings.logo
                        ? `${GlobalSettings.Settings.Server}/${workspaceSettings.logo}`
                        : "/assets/images/logo-for-web.png"
                }
                alt=""
                width="40"
                height="40"
            />
        </>
    );
    let titleClergiesAppointmentDiv = <></>;
    if (clergyFirst) {
        titleClergiesAppointmentDiv = titleClergiesAppointment;
    } else {
        titleClergiesAppointmentDiv = titleClergiesAppointmentImg;
    }

    return (
        <div
            className={`mx-auto py-4 md:grid md:grid-cols-12 text-white bg-primary`}
        >
            <div className="hidden md:block lg:hidden col-span-2"></div>
            <div className="search-title m-auto col-span-10 hidden md:block lg:hidden whitespace-nowrap text-2xl">
                {titleClergiesAppointment}
            </div>

            <div className="col-span-1 md:col-span-2 lg:col-span-1 search-title mb-3 md:m-auto sm:px-2 text-center">
                <span className="block md:hidden">
                    {titleClergiesAppointment}
                </span>
                <span className="hidden md:block text-lg">
                    {titleClergiesAppointmentImg}
                </span>
            </div>

            <div className="col-span-4 md:col-span-10 lg:col-span-4 search-inputs grid md:grid-cols-3 gap-3 items-center px-3 lg:px-0 mac:my-3 mr-2">
                <div className="search-inputs--positions bg-white rounded-[5px]">
                    <Select
                        color="default"
                        items={POSITIONS}
                        size="sm"
                        placeholder="Chọn Chức Vụ"
                        className="bg-white text-gray-400 rounded-[5px]"
                        defaultSelectedKeys={
                            positionValue ? [positionValue] : []
                        }
                        onChange={(e) => setPositionValue(e.target.value)}
                    >
                        {(position) => (
                            <SelectItem key={position.code}>
                                {position.name}
                            </SelectItem>
                        )}
                    </Select>
                </div>

                <div className="search-inputs--deanery bg-white rounded-[5px]">
                    <Select
                        items={deaneriesData}
                        size="sm"
                        placeholder="Chọn Giáo Hạt"
                        defaultSelectedKeys={deaneryValue ? [deaneryValue] : []}
                        onChange={(e) => setDeaneryValue(e.target.value)}
                        className="bg-white text-gray-400 rounded-[5px]"
                    >
                        {deaneriesData.value.map((appointment) => {
                            return (
                                <SelectItem key={appointment.id}>
                                    {appointment.name}
                                </SelectItem>
                            );
                        })}
                    </Select>
                </div>

                <div className="search-inputs__name text-black">
                    <div className="w-full">
                        <Input
                            size="lg"
                            key="none"
                            radius="none"
                            type="text"
                            placeholder="Nhập Tên"
                            classNames={{
                                inputWrapper: "font-semibold rounded-[5px]",
                            }}
                            defaultValue={priestNameValue}
                            onChange={handlePriestNameChange}
                        />
                    </div>
                </div>
            </div>

            <div className="hidden md:block lg:hidden col-span-2"></div>
            <div className="search-actions col-span-10 lg:col-span-2 grid grid-cols-2 text-center gap-5 items-center mt-5 sm:my-auto md:mt-2 lg:my-auto">
                <div className="m-auto">
                    <Button
                        size="md"
                        onClick={handleSearch}
                        className="w-28 xl:w-fit rounded-[10px] text-white font-bold bg-link text-xs xl:px-5 px-5 sm:px-0"
                    >
                        TÌM KIẾM
                    </Button>
                </div>

                <div className="m-auto">
                    <Button
                        size="md"
                        className="w-28 xl:w-fit rounded-[10px] text-primary font-bold bg-custom text-xs"
                        onPress={() => openDetail()}
                    >
                        PHÓNG TO
                    </Button>
                </div>
            </div>

            {!clergyFirst && (
                <>
                    <div className="search-title m-auto col-span-5 hidden lg:block whitespace-nowrap text-2xl">
                        {titleClergiesAppointment}
                    </div>
                </>
            )}

            <div
                className={`search-result grid grid-cols-6 col-span-5 lg:auto-cols-max mac:items-center lg:mt-0 ml-2 ${
                    clergyFirst ? "mt-5" : ""
                }`}
            >
                {clergyFirst && (
                    <>
                        <div className="infos-image m-auto">
                            <Image
                                className="flex justify-center"
                                alt=""
                                width={50}
                                height={50}
                                src={
                                    sharedService.isValidUrl(clergyFirst?.photo)
                                        ? `${clergyFirst?.photo?.replace(
                                              "public/",
                                              ""
                                          )}`
                                        : clergyFirst?.photo?.replace(
                                              "public/",
                                              ""
                                          )
                                        ? `${
                                              GlobalSettings.Settings.Server
                                          }/${clergyFirst?.photo?.replace(
                                              "public/",
                                              ""
                                          )}`
                                        : "/assets/images/logo-for-web.png"
                                }
                            />
                        </div>

                        <div className="col-span-5">
                            <p className="font-bold ml-3 text-yellow-300">
                                Linh Mục{" "}
                                {clergyFirst?.stName
                                    ? clergyFirst?.stName +
                                      " " +
                                      clergyFirst?.name
                                    : ""}
                            </p>

                            <div className="flex">
                                <div className="infos-data ml-3 truncate">
                                    <p className="truncate">
                                        {clergyFirst.status === "rip" ? (
                                            <>
                                                <span>
                                                    Tình trạng: Đã Mất
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                Chức vụ:{" "}
                                                <span
                                                    className={
                                                        clergyFirst?.positionName
                                                            ? ""
                                                            : "italic"
                                                    }
                                                >
                                                    {clergyFirst?.positionName ||
                                                        "Đang cập nhật"}
                                                </span>
                                            </>
                                        )}
                                    </p>
                                    {clergyFirst.status !== "rip" && (
                                        <>
                                            <p className="truncate">
                                                <Link
                                                    href={`/nha-tho/${clergyFirst?.workPlaceId}`}
                                                    className="text-link"
                                                >
                                                    Nơi phục vụ:{" "}
                                                    {clergyFirst?.workPlace}
                                                </Link>
                                            </p>
                                        </>
                                    )}

                                    {/* Mobile layout: start */}
                                    <p className="lg:hidden">
                                        Chịu chức:{" "}
                                        {clergyFirst?.clergyDay && (
                                            <>
                                                {" "}
                                                {clergyFirst.clergyDay.replaceAll(
                                                    "/",
                                                    "-"
                                                )}{" "}
                                            </>
                                        )}
                                    </p>
                                    <p className="lg:hidden">
                                        Năm sinh:{" "}
                                        {clergyFirst?.birthDay && (
                                            <>
                                                {" "}
                                                {clergyFirst.birthDay.replaceAll(
                                                    "/",
                                                    "-"
                                                )}{" "}
                                            </>
                                        )}
                                    </p>
                                    {/* Mobile layout: end */}
                                </div>

                                <div className="infos-anniversaries mx-3 hidden lg:block truncate">
                                    <p className="truncate">
                                        Chịu chức:{" "}
                                        {clergyFirst?.clergyDay && (
                                            <>
                                                {" "}
                                                {clergyFirst.clergyDay.replaceAll(
                                                    "/",
                                                    "-"
                                                )}{" "}
                                            </>
                                        )}
                                    </p>
                                    <p className="truncate">
                                        Năm sinh:{" "}
                                        {clergyFirst?.birthDay && (
                                            <>
                                                {" "}
                                                {clergyFirst.birthDay.replaceAll(
                                                    "/",
                                                    "-"
                                                )}{" "}
                                            </>
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {clergiesModal}
        </div>
    );
}
